<template>
  <b-overlay :show="loading">
    <div class="formBoder">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Page No" vid="page_no" rules="required" v-slot="{ errors }">
              <b-form-group
                class="row"
                label-cols-sm="12"
                id="page_no"
                label-for="Page No"
              >
              <template v-slot:label>
                Page No <span>*</span>
              </template>
                <b-form-input
                  id="page_no"
                  v-model="form.page_no"
                  placeholder="Enter Page No"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="12" md="12" sm="12" xs="12">
            <ValidationProvider name="Page Type" vid="page_type" rules="required">
                <b-form-group
                  label-for="page_type"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Page Type
                </template>
                <b-form-select
                  plain
                  v-model="form.page_type"
                  :options="pageTypeList"
                  id="page_type"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      <div class="row mt-3">
        <div class="col-sm-3"></div>
          <div class="col text-right">
            <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
            &nbsp;
            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
          </div>
      </div>
     </b-form>
    </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import RestApi, { apiBaseURL } from '@/config'

export default {
  props: ['editItem'],
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      form: {
        page_no: '',
        page_type: 0
      },
      errors: [],
      valid: null,
      loading: false,
      parentList: []
    }
  },
  created () {
    if (this.editItem) {
      this.form = this.editItem
    }
  },
  computed: {
    pageTypeList: function () {
      const list = [
        { value: 'preface', text: 'preface' },
        { value: 'main_body', text: 'Main Body' },
        { value: 'table_of_contents', text: 'Table of Contents' }
      ]
      return list
    }
  },
  methods: {
    async submitData () {
      this.loading = true
      let result = ''
      if (this.form.id) {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/update_book_page_no_data', this.form)
      } else {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/store_book_page_no_data', this.form)
      }
      this.loading = false
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
