<template>
  <div class="section-wrapper">
  <b-overlay :show="loader">
    <!-- <div class="row m-5">
      <div class="col-md-4 text-center">
      </div>
      <div class="col-md-4 text-center">
        <qr-code class="qr-code-component" :size="200" color="#2ecc71" :text="editItem.page_no.toString()"></qr-code>
      </div>
      <div class="col-md-4 text-center">
      </div>
    </div> -->
    <b-card class="mb-3" v-for="(item, index) in detailData" :key="index">
      <b-card-title>
       <h6 v-if="item.section">{{ item.section.section_name }} : {{ item.section.section_title }}</h6>
      </b-card-title>
      <b-card-text>
        <div class="row mb-3" v-if="item.tags.length">
          <div class="col-lg-12">
            Tags:
            <span v-for="(tag, tagIndex) in item.tags" :key="tagIndex">
              <span class="badge badge-pill badge-info mr-1" v-html="tag.name_en"></span>
            </span>
          </div>
        </div>
        <p v-if="item.page_content" v-html="item.page_content"></p>
     </b-card-text>
    </b-card>
    </b-overlay>
  </div>
</template>
<script>
import RestApi, { apiBaseURL } from '@/config'
export default {
  props: ['editItem'],
  name: 'Content',
  components: {
  },
  data () {
    return {
      loader: false,
      detailData: []
    }
  },
  created () {
  },
  mounted () {
    this.getBookContentByPageNo(this.editItem.page_no)
  },
  computed: {
  },
  methods: {
    getBookContentByPageNo (pageNo) {
      this.loader = true
      const params = Object.assign({}, { page_no: pageNo })
      RestApi.getData(apiBaseURL, 'admin/ajax/get_book_contents_by_page_no', params).then(response => {
        if (response.success) {
          if (response.data.length) {
            this.detailData = response.data
          }
          this.loader = false
        }
      })
    }
  }
}
</script>
<style>
.qr-code-component {}
.qr-code-component img {
  display: inline !important;
}
</style>
